<template>
  <div class="content receipt-note-list">
    <breadcrumb />
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.receiptNoteSn"
            :placeholder="$t('收款单号')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`form.Search`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :statusList="receipt_note_status"
      :columns="columns"
      :loading="loading"
      :dataSource="tableData"
      :scroll="{ x: 1500 }"
      :showSelect="true"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      @search="getList"
    >
      <template v-slot:receiptNoteSn="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
      <template v-slot:receiptAmount="{ text, record }">
        <PriceInput :value="text" :currency="record.receiptCurrency" :showTxt="true" />
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'ReceiptNoteList',
  components: { CommonTable },
  data() {
    return {
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      total: 0,
      loading: false,
    }
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    ...mapState(['receipt_note_status']),
    ...mapGetters(['receiptNoteTypeMapping', 'receiptNoteStatusMapping', 'currencyListMapping']),
    columns() {
      return [
        {
          title: this.$t('收款单号'),
          dataIndex: 'receiptNoteSn',
          width: 200,
          scopedSlots: { customRender: 'receiptNoteSn' },
        },
        {
          title: this.$t('收款组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          customRender: (text) => this.receiptNoteTypeMapping[text],
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text) => this.receiptNoteStatusMapping[text],
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
        },
        {
          title: this.$t('收款币别'),
          dataIndex: 'receiptCurrency',
          customRender: (text) => this.currencyListMapping[text],
        },
        {
          title: this.$t('收款金额'),
          dataIndex: 'receiptAmount',
          scopedSlots: { customRender: 'receiptAmount' },
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
        },
        {
          title: this.$t('结算方式'),
          dataIndex: 'settlementMethod',
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
      ]
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getReceiptNoteList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'receiptNoteDetail/' + record.receiptNoteSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.receiptNoteSn,
        },
        route: `/receivableManage/receiptNoteDetail?id=${record.id}&sn=${record.receiptNoteSn}`,
      })
    },
  },
}
</script>
